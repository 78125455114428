* {
  box-sizing: border-box;
}

html {
  @media (max-width: 768px) {
    font-size: 10pt;
  }
  font-size: 12pt;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;

  button:focus {
    outline: 0;
  }
}

#react-root {
  height: 100%;
  overflow-y: auto;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  background-color: #fcfcfc;
  color: rgb(94, 94, 94);
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: lighter;
    margin: 0;
    padding: 0;
    text-align: center;
  }
}

#app {
  @extend .flexCenter;
  flex-direction: column;
  section {
    @extend .flexCenter;
    flex-direction: column;
    margin-bottom: 4rem;
  }

  #head {
    img {
      max-width: 200px;
      height: auto;
    }
    padding-top: 2.5rem;
    h1 {
      padding-top: 0.75rem;
    }
  }

  #hero {
    img {
      width: 100%;
      height: auto;
      max-width: 400px;
    }
    display: inline-block;
    padding-left: 4rem;
    padding-right: 4rem;
    text-align: center;
  }

  .box {
    max-width: 768px;
    background-color: transparentize(lightgray, 0.5);
    border-radius: 2rem;
    padding: 2rem;
    p {
      width: 100%;
    }
    h1,
    h2 {
      padding-bottom: 2rem;
    }
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    .slack-link {
      padding-top: 1rem;
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      text-decoration: none;
      color: black;
      font-size: 2rem;
      img {
        margin-left: -0.5rem;
        width: 8rem;
        height: auto;
      }
    }
    form {
      @extend .flexCenter;
      flex-direction: row;
      width: 100%;

      div {
        // fix recaptcha behaviour caused by "fixed" being locked to the containing element that has a "tranform"
        transform: unset;
      }

      button,
      input {
        font-size: 1rem;
        font-weight: lighter;
        height: 3rem;
        border: thin solid #555;
        border-radius: 0;
      }

      button {
        background-color: mediumseagreen;
        color: white;
        text-transform: uppercase;
        padding: 0 1rem;
        margin-left: 0.25rem;
        cursor: pointer;
        &:hover:not(.disabled) {
          background-color: lighten(mediumseagreen, 0.2);
        }
        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          background-color: darken(mediumseagreen, 0.2);
        }
      }

      input {
        flex: 1 1 auto;
        background-color: white;
        color: black;
        padding: 0.5rem;
        outline: 0;
      }
    }
  }
}
